import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import ModaleOuverture from '../ModaleOuverture/ModaleOuverture';
import ModaleContact from '../ModaleContact/ModaleContact';
import ModaleListeCourse from '../ModaleListeCourse/ModaleListeCourse';
import './Header.scss';

function Header() {
    const [showModal, setShowModal] = useState(false);
    const [showHeaderDropdown, setShowHeaderDropdown] = useState(false);
    const [showProduitDropdown, setShowProduitDropdown] = useState(false);
    const [isContactModalOpen, setContactModalOpen] = useState(false);
    const [isListeCourseModalOpen, setListeCourseModalOpen] = useState(false);
    const { isAdminLoggedIn, setIsAdminLoggedIn, logout } = useAuth();
    const headerDropdownRef = useRef(null);
    const produitDropdownRef = useRef(null);

    const closeModal = () => {
        setShowModal(false);
        localStorage.setItem('modalClosedTime', new Date().getTime());
    };

    const toggleContactModal = () => {
        setContactModalOpen(!isContactModalOpen);
    };

    const toggleListeCourseModal = () => {
        setListeCourseModalOpen(!isListeCourseModalOpen);
    };


    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsAdminLoggedIn(!!token);
    }, []);
    

    useEffect(() => {
        const modalClosedTime = localStorage.getItem('modalClosedTime');
        if (modalClosedTime) {
            const tempsLocalStorage = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
            if (modalClosedTime > tempsLocalStorage) {
                setShowModal(false);
                return;
            }
        }
        setShowModal(true);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
                setShowHeaderDropdown(false);
            }
            if (produitDropdownRef.current && !produitDropdownRef.current.contains(event.target)) {
                setShowProduitDropdown(false);
            }
        };
    

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
             {isAdminLoggedIn && (
                <div className="admin-bandeau">
                    <Link to='/admin'>Vers Admin</Link>
                    <button onClick={logout} className='logout__button'>Se déconnecter</button>
                    </div>
                )}
        <header className="header">
            
            <div className="logo">
                <img src="/assets/phrase_header.png" alt="Vos courses bio et sans emballage à Roanne" />
            </div>
            <div className="search-bar">
                <input type="text" placeholder="Rechercher un produit" />
            </div>
            <nav className="navigation">
                <Link to="/" className='LinkNoStyle'>
                    Accueil
                </Link>
                <div className='header-dropdown' ref={produitDropdownRef} onClick={() => setShowProduitDropdown(!showProduitDropdown)}>
                    <h1 className='header__h1'>Produits</h1>
                    <div className={`produits-dropdown-content ${showProduitDropdown ? 'show-dropdown' : ''}`}>
                        <Link to="/produits" className='LinkNoStyle'>
                            Les produits
                        </Link>
                        <div className='dropdown-option' onClick={toggleListeCourseModal}>
                            Votre liste de courses
                        </div>
                    </div>
                </div>
                <div className='header-dropdown' ref={headerDropdownRef} onClick={() => setShowHeaderDropdown(!showHeaderDropdown)}>
                    <h1 className='header__h1'>Épicerie</h1>
                    <div className={`epicerie-dropdown-content ${showHeaderDropdown ? 'show-dropdown' : ''}`}>
                        <Link to="/producteurs" className='LinkNoStyle'>
                            Les producteurs
                        </Link>
                        <div className='dropdown-option' onClick={() => { setShowModal(true); setShowHeaderDropdown(false); }}>
                            En ce moment
                        </div>
                    </div>
                </div>
                <div role='button' onClick={toggleContactModal} onKeyDown={toggleContactModal} tabIndex={0} className='contact'>
                    Contact
                </div>
            </nav>
            {isContactModalOpen && <ModaleContact onClose={toggleContactModal}/>}
            {showModal && <ModaleOuverture onClose={closeModal} />}
            {isListeCourseModalOpen && <ModaleListeCourse onClose={toggleListeCourseModal} />}
        </header>
        </div>
    );
}

export default Header;
