import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

function NotFound() {
  return (
    <div className="NotFound">
      <img src='/assets/error404.webp' alt='pot cassé' className='NotFound__img'/>
      <p className='NotFound__p'>Oups! La page que vous demandez n'existe pas.<br /> (erreur 404)</p>
      <Link to="/" className='LinkNoStyle'>Retourner à la page d'accueil</Link>
    </div>
  );
};

export default NotFound;