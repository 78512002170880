import React from 'react';
import './SiteMap.scss';

import { Link } from 'react-router-dom';


function Accesibility() {
  return (
    <div className="siteMap">
        <div className='siteMap__div'>
            <div className='siteMap-content__div'>
                <Link to="/" className='LinkNoStyle'><h1 className='siteMap__h1'>Accueil :</h1></Link>
                <p className='siteMap__p'>Page principale présentant une vue d'ensemble de notre épicerie bio en vrac à Roanne.</p>
            </div>
            <div className='siteMap-content__div'>
                <div className='siteMap-groupe-h'>
                <Link to="/epicerie" className='LinkNoStyle'><h1 className='siteMap__h1'>Epicerie :</h1></Link>
                <h2 className='siteMap__h2'>(en cours de construction)</h2>
                </div>
                <p className='siteMap__p'>Apprenez en plus sur nos producteurs, les évènements et autres</p>
            </div>
            <div className='siteMap-content__div'>
                <div className='siteMap-groupe-h'>
                <Link to="/produits" className='LinkNoStyle'><h1 className='siteMap__h1'>Produits :</h1></Link>
                <h2 className='siteMap__h2'>(en cours de construction)</h2>
                </div>
                <p className='siteMap__p'>Découvrez notre gamme de produits bio et sans emballage disponibles en vrac, y compris 
                des fruits secs, des céréales, des légumineuses, des épices, etc.</p>
            </div>
            <div className='siteMap-content__div'>
                <h1 className='siteMap__h1'>Contact :</h1>
                <p className='siteMap__p'>Retrouvez tous les moyens pour nos contacter (adresse, téléphone, e-mail, réseaux sociaux) directement
                en haut de votre page, dans l'en-tête du site.</p>
            </div>
            <div className='siteMap-content__div'>
                <Link to="/accessibility" className='LinkNoStyle'><h1 className='siteMap__h1'>Accessibilité :</h1></Link>
                <p className='siteMap__p'>Page décrivant notre politique d'accessibilité, notre engagement et les moyens de nous contacter 
                pour des demandes spécifiques</p>
            </div>
            <div className='siteMap-content__div'>
                <Link to="/confidentiality" className='LinkNoStyle'><h1 className='siteMap__h1'>Vos données :</h1></Link>
                <p className='siteMap__p'>Page décrivant notre politique de confidentialité.</p>
            </div>
        </div>
    </div>
  );
};

export default Accesibility;