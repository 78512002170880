function fetchWithAuth(url, options, logout) {
  return fetch(url, options)
      .then(response => {
          if (!response.ok) { // Vérifie si la réponse est une erreur
              if (response.status === 401) {
                  logout(); // Gérer la déconnexion si l'utilisateur n'est pas autorisé
              }
              throw new Error('Network response was not ok.');
          }
          return response; // Retourner la réponse pour la chaîne de promesses suivante
      });
}

export default fetchWithAuth;
