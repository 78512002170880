import React, { useState } from 'react';
import './ModalQuantite.scss';


function ModalQuantite({ article, onClose }) {
  const [quantite, setQuantite] = useState(1);

  const handleAjoutListe = () => {
    const quantiteNum = Number(quantite);
    let listeCourse = JSON.parse(localStorage.getItem('listeCourse')) || [];
    const existingItemIndex = listeCourse.findIndex(item => item.nom === article.nom && item.unite === article.unite);

    if (existingItemIndex !== -1) {
        listeCourse[existingItemIndex].mesure += quantiteNum;
    } else {
        const item = {
            nom: article.nom,
            mesure: quantiteNum,
            unite: article.unite
        };
        listeCourse.push(item);
    }

    localStorage.setItem('listeCourse', JSON.stringify(listeCourse));
    onClose();
  };

  return (
    <div className="modal-quantite">
      <div className="overlay-quantite" onClick={onClose}></div>
      <div className="modal-content-quantite">
        <h1 className='modalQuantite__h1'>{article.nom}</h1>
        <div className='content-label-quantite'>
          <label>Quelle quantité désirez-vous ?</label>
          <div className='content-quantite'>
            <input type="number" value={quantite} onChange={(e) => setQuantite(e.target.value)} min="1" />
            <span>{article.unite}</span>
          </div>
        </div>
        <button onClick={handleAjoutListe} className='modal-quantite__button'>Ajouter à la liste de courses</button>
      </div>
    </div>
  );
}

export default ModalQuantite;
