import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.scss';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <footer className="footer">
      <div className="footer__div">
        <h1 className='h-footer'>Suivez BAsique!</h1>
          <div className='footer-content__div'>
            <a href="https://www.facebook.com/epicerie.basique/" target="_blank" rel="noopener noreferrer" aria-label='lien vers Facebook'>
              <FontAwesomeIcon icon={faFacebook} size="2x" className='icon-footer' />
            </a>
            <a href="https://www.instagram.com/epiceriebasique/" target="_blank" rel="noopener noreferrer" aria-label='lien vers Instagram'>
              <FontAwesomeIcon icon={faInstagram} size="2x" className='icon-footer'/>
            </a>
          </div>
      </div>
      <div className="footer__div">
        <h1 className='h-footer'>Liens</h1>
        <div className='footer-content__div'>
          <Link to="/" className='LinkNoStyle' onClick={scrollToTop}>
            Accueil
          </Link>
          <Link to="/epicerie" className='LinkNoStyle' onClick={scrollToTop}>
            Épicerie
          </Link>
          <Link to="/produits" className='LinkNoStyle' onClick={scrollToTop}>
            Produits
          </Link>
        </div>
      </div>
      <div className='footer__div'>
        <h1 className='h-footer'>Navigation</h1>
        <div className='footer-content__div'>
          <Link to="/sitemap" className='LinkNoStyle' onClick={scrollToTop}>
            Plan du Site
          </Link>
          <Link to="/accessibility" className='LinkNoStyle' onClick={scrollToTop}>
            Accessibilité
          </Link>
          <Link to="/confidentiality" className='LinkNoStyle' onClick={scrollToTop}>
            Vos données
          </Link>
          <Link to="/admin" className='LinkNoStyle' onClick={scrollToTop}>
            Connexion
          </Link>
        </div>
      </div>
      <div className="footer__div">
        <h1 className='h-footer'>Mentions légales</h1>
        <div className='footer-content__div'>
          <p>Epicerie BAsique</p>
          <p>Gérante: Eva Traccard</p>
          <p>SIRET: 94803082000013 </p>
          <p>Adresse: 17 rue Anatole France</p>
          <p>42300 Roanne</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
