import React, { useState, useEffect } from 'react';
import { faTimes, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_ROUTES } from '../../utils/constants';
import './ModaleOuverture.scss';

function ModaleOuverture({ onClose }) {
  const [isOpen, setIsOpen] = useState(true);
  const [titre, setTitre] = useState('');
  const [message, setMessage] = useState('');
  const [youtubeId, setYoutubeId] = useState(null);

  useEffect(() => {
    fetch(API_ROUTES.MODALE)
      .then(response => response.json())
      .then(data => {
        setTitre(data.titre);
        setMessage(data.message);
        const youtubeLink = extractYoutubeId(data.message);
        if (youtubeLink) {
          setYoutubeId(youtubeLink);
        }
      })
      .catch(error => console.error('Erreur lors du chargement du contenu de la modale', error));
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => onClose(), 300);
  };

  // Fonction pour extraire l'ID YouTube à partir du message
  const extractYoutubeId = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = text.match(regex);
    return match ? match[1] : null;
  };

  // Fonction pour vérifier si le message est un lien YouTube
  const isYoutubeLink = (text) => {
    return text.startsWith("https://www.youtube.com/") || text.startsWith("https://youtu.be/");
  };

  return (
    <div className='modaleOuverture'>
      <div className={`overlay ${isOpen ? '' : 'closing'}`} onClick={handleClose}></div>
      <div className={`modal-content ${isOpen ? '' : 'closing'}`}>
        <div className='cloche-cross__icon'>
          <FontAwesomeIcon icon={faBell} className='cloche-icon' />
          <button onClick={handleClose} className="close-button" aria-label="Fermer">
            <FontAwesomeIcon icon={faTimes} className='close-icon'/>
          </button>
        </div>
        <h1 className='modaleOuverture__h1'>En ce moment chez BAsique !</h1>
        <h2 className='modaleOuverture__h2'>{titre}</h2>
        <div className='modaleOuverture-content'>
          {/* On n'affiche le message que s'il ne s'agit pas d'un lien YouTube */}
          {!isYoutubeLink(message) && (
            <p className='modaleOuverture__p'>{message}</p>
          )}
          {/* Si le message contient un lien YouTube, on affiche la vignette */}
          {youtubeId && (
            <div className="youtube-thumbnail">
              <a href={`https://www.youtube.com/watch?v=${youtubeId}`} target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`} alt="YouTube Thumbnail" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModaleOuverture;
