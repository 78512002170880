import React from 'react';
import CardProducteur from '../../components/CardProducteur/CardProducteur';

function Producteurs() {
  return (
    <div className="Producteurs">
      <CardProducteur />
    </div>
  );
}

export default Producteurs;
