import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setIsAdminLoggedIn(false);
        } else {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp < currentTime) {
                    setIsAdminLoggedIn(false);
                    localStorage.removeItem('token');
                } else {
                    setIsAdminLoggedIn(true);
                }
            } catch (error) {
                setIsAdminLoggedIn(false);
                localStorage.removeItem('token');
            }
        }
    }, []);
    

    const logout = () => {
        localStorage.removeItem('token');
        setIsAdminLoggedIn(false);
        window.location.href = '/login';
    };

    return (
        <AuthContext.Provider value={{ isAdminLoggedIn, setIsAdminLoggedIn, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
