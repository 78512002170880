import React from 'react';
import { Link } from 'react-router-dom';
import './EnConstruction.scss';

function EnConstruction() {
  return (
    <div className="EnConstruction">
      <img src='/assets/EnConstruction.webp' alt='pot en cours de réparation' className='EnConstruction__img'/>
      <p className='EnConstruction__p'>La page que vous demandez est en cours de construction...<br />
      Retrouvez bientôt tous nos produits, nos producteurs et plus encore dans les pages dédiées.</p>
      <Link to="/" className='LinkNoStyle'>Retourner à la page d'accueil</Link>
    </div>
  );
};

export default EnConstruction;