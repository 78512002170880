import React, { useState, useEffect, useRef } from 'react';
import { faBell, faPen, faPlus, faStore, faTag, faTruckFast, faArrowRotateLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_ROUTES } from '../../utils/constants';
import fetchWithAuth from '../../utils/fetchWithAuth';
import { useAuth } from '../../AuthContext';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { UnitforCardArticle } from '../../utils/unitConverter';



import './Admin.scss';
import 'react-toastify/dist/ReactToastify.css';




function Admin() {

    
    const [loading, setLoading] = useState(false);
    const { logout } = useAuth();
    const [errors, setErrors] = useState({});

        /** MODALE **/
    const [titre, setTitre] = useState('');
    const [message, setMessage] = useState('');
    const titreRef = useRef(null);
    const messageRef = useRef(null);

        /** HORAIRE **/
    const [horaire, setHoraire] = useState('');
    const horaireRef = useRef(null);

        /** ARTICLE **/
    const [nom, setNom] = useState('');
    const [prix, setPrix] = useState('');
    const [unite, setUnite] = useState('');
    const [mesure, setMesure] = useState('');
    const [photo, setPhoto] = useState(null);
    const [vrac, setVrac] = useState(false);
    const [local, setLocal] = useState(false);
    const [categorie, setCategorie] = useState('');
    const [distributeur, setDistributeur] = useState('');
    const [description, setDescription] = useState('');
    const [composition, setComposition] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const [articles, setArticles] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState('');
    const [deleteCategorie, setDeleteCategorie] = useState('');
    const [deleteDistributeur, setDeleteDistributeur] = useState('');
    const nomRef = useRef(null);
    const prixRef = useRef(null);

        /** CATEGORIE **/
    const nouvelleCategorieRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryName, setCategoryName] = useState('');

        /** FOURNISSEUR **/
    const nouveauFournisseurRef = useRef(null);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [selectedFournisseur, setSelectedFournisseur] = useState('');
    const [fournisseurName, setFournisseurName] = useState('');

        /** PRODUCTEUR **/

    const [nomProducteur, setNomProducteur] = useState('');
    const [adresseProducteur, setAdresseProducteur] = useState('');
    const [siteProducteur, setSiteProducteur] = useState('');
    const [photoProducteur, setPhotoProducteur] = useState(null);
    const [previewImageProducteur, setPreviewImageProducteur] = useState(null);
    const [producteurCards, setProducteurCard] = useState([]);
    const [selectedProducteurCard, setSelectedProducteurCard] = useState('');
    const [producteurCardObjectId, setProducteurCardObjectId] = useState('');
    const [fournisseursSansCarteProducteur, setFournisseursSansCarteProducteur] = useState([]);
    const [fournisseursAvecCarteProducteur, setFournisseursAvecCarteProducteur] = useState([]);


    const nomProducteurRef = useRef(null);
    const adresseProducteurRef = useRef(null);
    const siteProducteurRef = useRef(null);



        /** BOUTONS **/
    const [activeBloc, setActiveBloc] = useState(null);
    const [activeButton1, setActiveButton1] =useState(null);
    const [activeButton2, setActiveButton2] =useState(null);


        /** SHOW BUTTON **/

            /* ARTICLE */
    const [showArticleButton, setShowArticleButton] = useState(false);
    const [showArticleOptionButton, setShowArticleOptionButton] = useState(false);

            /* CATEGORIE */
    const [showCategoryOptionButton, setShowCategoryOptionButton] = useState(false);

            /* FOURNISSEUR */
    const [showSupplyOptionButton, setShowSupplyOptionButton] = useState(false);

        /** SHOW CONTENT **/

            /* MODALE */
    const [showModaleContent, setShowModaleContent] = useState(false);

            /* HORAIRE */
    const [showHoraireContent, setShowHoraireContent] = useState(false);

            /* ARTICLE */
    const [showArticleContent, setShowArticleContent] = useState(false);
    const [showModifyArticleContent, setShowModifyArticleContent] = useState(false);
    const [showDeleteArticleContent, setShowDeleteArticleContent] = useState(false);

            /* CATEGORIE */
    const [showAddCategoryContent, setShowAddCategoryContent] = useState(false);
    const [showModifyCategoryContent, setShowModifyCategoryContent] = useState(false);
    const [showDeleteCategoryContent, setShowDeleteCategoryContent] = useState(false);

            /* FOURNISSEUR */
    const [showAddFournisseurContent, setShowAddFournisseurContent] = useState(false);
    const [showModifyFournisseurContent, setShowModifyFournisseurContent] = useState(false);
    const [showDeleteFournisseurContent, setShowDeleteFournisseurContent] = useState(false);

            /* PRODUCTEUR*/
    const [showProducteursContent, setShowProducteursContent] = useState(false);
    const [showAddProducteursContent, setShowAddProducteursContent] = useState(false);
    const [showModifyProducteursContent, setShowModifyProducteursContent] = useState(false);
    const [showDeleteProducteursContent, setShowDeleteProducteursContent] = useState(false);

    //* gestion des erreurs lors de l'envoi du formulaire *//
    const validateForm = () => {
        let newErrors = {};
        let isValid = true;
    
        if (!nom.trim()) {
            newErrors.nom = 'Le nom de l\'article est requis';
            isValid = false;
        }
        if (!prix.trim()) {
            newErrors.prix = 'Le prix est requis';
            isValid = false;
        }
        if (!mesure.trim()) {
            newErrors.mesure = 'La mesure est requise';
            isValid = false;
        }
        if (!unite.trim()) {
            newErrors.unite = 'L\'unité est requise';
            isValid = false;
        }
        if (!photo) {
            newErrors.photo = 'Une photo est requise';
            isValid = false;
        }
        if (!categorie.trim()) {
            newErrors.categorie = 'La catégorie est requise';
            isValid = false;
        }
        if (!distributeur.trim()) {
            newErrors.distributeur = 'Le distributeur est requis';
            isValid = false;
        }
        if (!description.trim()) {
            newErrors.description = 'La description est requise';
            isValid = false;
        }
        if (!composition.trim()) {
            newErrors.composition = 'La composition est requise';
            isValid = false;
        }
    
        setErrors(newErrors);
        return isValid;
    };

    const validateFormSupplier = () => {
        let newErrors = {};
        let isValid = true;
    
        if (!nomProducteur.trim()) {
            newErrors.nomProducteur = 'Le nom de l\'article est requis';
            isValid = false;
        }
        if (!photoProducteur) {
            newErrors.photoProducteur = 'Une photo est requise';
            isValid = false;
        }

        if (!adresseProducteur.trim()) {
            newErrors.adresseProducteur = 'L\'adresse est requise';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    
    

    //* textarea et input *//

        /* => la hauteur s'adapte au contenu */

    useEffect(() => {
        if (titreRef.current) {
            titreRef.current.style.height = 'auto';
            titreRef.current.style.height = `${titreRef.current.scrollHeight}px`;
        }
        if (messageRef.current) {
            messageRef.current.style.height = 'auto';
            messageRef.current.style.height = `${messageRef.current.scrollHeight}px`;
        }
        if (horaireRef.current) {
            horaireRef.current.style.height = 'auto';
            horaireRef.current.style.height = `${horaireRef.current.scrollHeight}px`;
        }

    }, [titre, message, horaire]);

        /* MODALE */

    const handleTextareaTitleChange = (e) => {
        setTitre(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleTextareaMessageChange = (e) => {
        setMessage(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

        /* HORAIRE */

    const handleTextareaHoraireChange = (e) => {
        setHoraire(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

        /* ARTICLE */

    const handleCategorieChange = (e) => {
        setCategorie(e.target.value);
    };
    
    const handleDistributeurChange = (e) => {
        setDistributeur(e.target.value);
    };
    
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    
    const handleCompositionChange = (e) => {
        setComposition(e.target.value);
    };
    
    //* Requête FETCH *//
       /** Chargement Base de données **/

       const loadData = () => {
        Promise.all([
            fetch(API_ROUTES.ARTICLE),
            fetch(API_ROUTES.CATEGORIE),
            fetch(API_ROUTES.FOURNISSEUR),
            fetch(API_ROUTES.MODALE),
            fetch(API_ROUTES.HORAIRE),
            fetch(API_ROUTES.PRODUCTEUR_CARD)
        ])
        .then(responses => Promise.all(responses.map(res => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        })))
        .then(([articlesData, categoriesData, fournisseursData, modaleData, horaireData, producteurCardData]) => {
            const sortedArticles = articlesData.sort((a, b) => a.nom.localeCompare(b.nom));            
            const sortedCategories = categoriesData.sort((a, b) => a.categorie.localeCompare(b.categorie));
            const sortedFournisseurs = fournisseursData.sort((a, b) => a.fournisseur.localeCompare(b.fournisseur));
    
            setTitre(modaleData.titre);
            setMessage(modaleData.message);
            setHoraire(horaireData.horaire);
    
            setArticles(sortedArticles);
            setCategories(sortedCategories);
            setFournisseurs(sortedFournisseurs);
            setProducteurCard(producteurCardData);
        })
        .catch(error => {
            console.error('Erreur lors du chargement des données', error);
        });
    };
    
    useEffect(() => {
        loadData();
    }, []);
    

        /** Soumission Base de données **/

            /* MODALE */

    const handleSubmitModale = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        fetchWithAuth(API_ROUTES.MODALE, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ titre, message }),
        }, logout)
        .then(response => response.json())
        .then(() => toast.success('Pop-up mise à jour avec succès'))
        .catch(error => toast.error('Erreur lors de la modification de la Pop-up.'));
    };

            /* HORAIRE */

    const handleSubmitHoraires = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        fetchWithAuth(API_ROUTES.HORAIRE, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ horaire }),
        }, logout)
        .then(response => response.json())
        .then(() => toast.success('Horaires mis à jour avec succès'))
        .catch(error => toast.error('Erreur lors de la modification des horaires'));
    };

            /* ARTICLE */

            /* ADD */

    const handleSubmitAddArticles = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Veuillez remplir tous les champs requis.');
            return;
        }

        const token = localStorage.getItem('token');
        
        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('prix', prix);
        formData.append('mesure', mesure);
        formData.append('unite', unite);
        formData.append('image', photo);
        formData.append('vrac', vrac);
        formData.append('local', local);
        formData.append('categorie', categorie);
        formData.append('distributeur', distributeur);
        formData.append('description', description);
        formData.append('composition', composition);
        
        setLoading(true);
        fetchWithAuth(API_ROUTES.ARTICLE, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        }, logout)
        .then(response => response.json())
        .then(() => {
            toast.success('Article créé avec succès!');
            loadData();
            RemiseZero();
        })
        .catch(error => toast.error('Erreur lors de la création de l\'article.'))
        .finally(() => 
            {setLoading(false);
            URL.revokeObjectURL(previewImage);

            });
    }

            /* SETUP SELECT*/

    const handleArticleSelect = (event) => {
        const articleId = event.target.value;
        const article = articles.find(a => a._id === articleId);
        setSelectedArticle(articleId);
        setNom(article ? article.nom : '');
        setPrix(article? article.prix : '');
        setUnite(article? article.unite : '');
        setMesure(article? article.mesure : '');
        setVrac(article? article.vrac : '');
        setLocal(article? article.local : '');
        setCategorie(article? article.categorie : '');
        setDistributeur(article? article.distributeur : '');
        setDescription(article? article.description: '');
        setComposition(article? article.composition: '');
        setPreviewImage(article? article.imageUrl: '');
        
        setDeleteCategorie(article ? categories.find(cat => cat._id === article.categorie)?.categorie : '');
        setDeleteDistributeur(article ? fournisseurs.find(fourn => fourn._id === article.distributeur)?.fournisseur : '');
    };


            /* MODIFY */

    const handleSubmitModifyArticles = (e) => {
        e.preventDefault();


        const token = localStorage.getItem('token');
        
        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('prix', prix);
        formData.append('mesure', mesure);
        formData.append('unite', unite);
        formData.append('image', photo);
        formData.append('vrac', vrac);
        formData.append('local', local);
        formData.append('categorie', categorie);
        formData.append('distributeur', distributeur);
        formData.append('description', description);
        formData.append('composition', composition);


        setLoading(true);
        fetchWithAuth(`${API_ROUTES.ARTICLE}/${selectedArticle}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        }, logout)
        .then(response => response.json())
        .then(() => {
            toast.success('Article modifié avec succès!');
            RemiseZero();
            loadData();
        })
        .catch(error => toast.error('Erreur lors de la modification de l\'article.'))
        .finally(() => 
            {setLoading(false);
            URL.revokeObjectURL(previewImage);
        });
    }

            /* DELETE */

    const handleSubmitDeleteArticles = (e) => {
        e.preventDefault();

        
        const token = localStorage.getItem('token');
        

        if (!selectedArticle) {
            alert('Veuillez sélectionner un article à supprimer.');
            return;        
        }

        setLoading(true);
        fetchWithAuth(`${API_ROUTES.ARTICLE}/${selectedArticle}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
        }, logout)
        .then(response => {
            if (!response.ok) {
                throw new Error('La suppression de l\'article a échoué');
            }
            return response.json();
        })
        .then(data => {
            toast.success('Article supprimé avec succès!');
            loadData();
            RemiseZero();
        })
        .catch(error => toast.error('Erreur lors de la suppression de l\'article.'))
        .finally(() => {setLoading(false);
        });
};
    
            /* CATEGORIE */
            
                /* ADD */

    const  handleSubmitAddCategory = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        const nouvelleCategorieValue = nouvelleCategorieRef.current.value;

        if (nouvelleCategorieValue.trim() === '') {
            alert('Veuillez saisir une catégorie.');
            return;
        }

        fetchWithAuth(API_ROUTES.CATEGORIE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ categorie: nouvelleCategorieValue }),
        }, logout)
        .then(response => response.json())
        .then(() => {
            toast.success('Catégorie ajoutée avec succès!');
            nouvelleCategorieRef.current.value = '';
            loadData();
        })
        .catch(error => toast.error('Erreur lors de la création de la catégorie.'));
    };

            /* SETUP SELECT*/

    const handleCategorySelect = (event) => {
        const categoryId = event.target.value;
        const category = categories.find(c => c._id === categoryId);
        setSelectedCategory(categoryId);
        setCategoryName(category ? category.categorie : '');
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

            /* MODIFY */

    const handleSubmitModifyCategory = (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token');

    if (!selectedCategory || !categoryName.trim()) {
        alert("Veuillez sélectionner une catégorie et entrer un nouveau nom valide.");
        return;
    }

    const updateData = {
        categorie: categoryName
    };

    fetchWithAuth(`${API_ROUTES.CATEGORIE}/${selectedCategory}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateData)
    }, logout)
    .then(response => {
        if (!response.ok) {
            throw new Error('Erreur réponse réseau');
        }
        return response.json();
    })
    .then(data => {
        toast.success('Catgéorie modifiée avec succès!');
        loadData();
        setSelectedCategory('');
    })
    .catch(error => toast.error('Erreur lors de la modification de la catégorie.'));
};
            /* DELETE */

    const handleSubmitDeleteCategory = (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token');
            
        if (!selectedCategory) {
                alert('Veuillez sélectionner une catégorie à supprimer.');
                return;
            }
            
            fetchWithAuth(`${API_ROUTES.CATEGORIE}/${selectedCategory}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }, logout)
            .then(response => {
                if (!response.ok) {
                    throw new Error('La suppression de la catégorie a échoué');
                }
                return response.json();
            })
            .then(data => {
                toast.success('Catégorie supprimée avec succès!');
                setCategoryName('');
                loadData();
            })
            .catch(error => toast.error('Erreur lors de la suppression de la catégorie.'));

            };
            

            /* FOURNISSEUR */
            
                /* ADD */

    const  handleSubmitAddSupplier = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        
        const nouveauFournisseurValue = nouveauFournisseurRef.current.value;
        
        if (nouveauFournisseurValue.trim() === '') {
            alert('Veuillez saisir un fournisseur .');
            return;
        }
        
        fetchWithAuth(API_ROUTES.FOURNISSEUR, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ fournisseur: nouveauFournisseurValue }),
        }, logout)
        .then(response => response.json())
        .then(() => {
            toast.success('Fournisseur créé avec succès!');
            nouveauFournisseurRef.current.value = '';
            loadData();
        })
        .catch(error => toast.error('Erreur lors de la création du fournisseur.'));
    };

                /* SETUP SELECT */

    const handleFournisseurSelect = (event) => {
        const fournisseurId = event.target.value;
        const fournisseur = fournisseurs.find(f => f._id === fournisseurId);
        setSelectedFournisseur(fournisseurId);
        setFournisseurName(fournisseur ? fournisseur.fournisseur : '');
        setNomProducteur(fournisseur ? fournisseur.fournisseur : ''); 
    };
            
    const handleFournisseurNameChange = (event) => {
        setFournisseurName(event.target.value);
    };

                /* MODIFY */

    const handleSubmitModifySupplier = (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token');
                
    if (!selectedFournisseur || !fournisseurName.trim()) {
        alert("Veuillez sélectionner un fournisseur et entrer un nouveau nom valide.");
            return;
    }
                
        const updateData = {fournisseur: fournisseurName};
                
        fetchWithAuth(`${API_ROUTES.FOURNISSEUR}/${selectedFournisseur}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updateData)
        }, logout)
        .then(response => {
            if (!response.ok) {
                throw new Error('Erreur réponse réseau');
            }
            return response.json();
        })
        .then(data => {
            toast.success('Fournisseur modifié avec succès!');
            loadData();
            setSelectedFournisseur('');
        })
        .catch(error => toast.error('Erreur lors de la modification du fournisseur.'));

    };

                /* DELETE */

                const handleSubmitDeleteSupplier = (event) => {
                    event.preventDefault();
                    const token = localStorage.getItem('token');
                
                    if (!selectedFournisseur) {
                        alert('Veuillez sélectionner un fournisseur à supprimer.');
                        return;
                    }
                
                    fetchWithAuth(`${API_ROUTES.FOURNISSEUR}/${selectedFournisseur}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }, logout)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('La suppression du fournisseur a échoué');
                        }
                        return response.json();
                    })
                    .then(data => {
                        toast.success('Fournisseur supprimé avec succès!');
                        setFournisseurName('');
                        if (producteurCardObjectId) {
                            return fetchWithAuth(`${API_ROUTES.PRODUCTEUR_CARD}/${producteurCardObjectId}`, {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                }
                            }, logout);
                        } else {
                            return null;
                        }
                    })
                    .then(cardResponse => {
                        if (cardResponse && !cardResponse.ok) {
                            throw new Error('La suppression de la carte producteur a échoué');
                        }
                        return cardResponse ? cardResponse.json() : null;
                    })
                    .then(cardData => {
                        if (cardData) {
                            toast.success('Carte producteur supprimée avec succès!');
                        }
                        loadData();
                    })
                    .catch(error => {
                        if (error.message !== 'La suppression du fournisseur a échoué') {
                            toast.error('Erreur lors de la suppression du fournisseur.');
                        }
                    });
                };
                
                

            /* Supplier Card*/
                /* ADD */

            
                useEffect(() => {
                    fetch(API_ROUTES.FOURNISSEUR)
                        .then(response => response.json())
                        .then(data => {
                            const fournisseursAvecCarteProducteurIds = producteurCards.map(card => card.fournisseurId);
                            const fournisseursAvecCarteProducteur = [];
                            const fournisseursSansCarteProducteur = [];
                            
                            data.forEach(fournisseur => {
                                if (fournisseursAvecCarteProducteurIds.includes(fournisseur._id)) {
                                    fournisseursAvecCarteProducteur.push(fournisseur);
                                } else {
                                    fournisseursSansCarteProducteur.push(fournisseur);
                                }
                            });
                
                            setFournisseursSansCarteProducteur(fournisseursSansCarteProducteur);
                            setFournisseursAvecCarteProducteur(fournisseursAvecCarteProducteur);
                        })
                        .catch(error => console.error('Error fetching fournisseurs:', error));
                }, [producteurCards]);
                
    
                
    const handleSubmitAddSupplierCard = async (event) => {
        event.preventDefault();
                
        if (!validateFormSupplier()) {
            toast.error('Veuillez remplir tous les champs requis.');
            return;
        }
                
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('nom', nomProducteur);
            formData.append('image', photoProducteur);
            formData.append('adresse', adresseProducteur);
            formData.append('siteWeb', siteProducteur);
            formData.append('fournisseurId', selectedFournisseur)
            
            setLoading(true);
                
            const response = await fetchWithAuth(API_ROUTES.PRODUCTEUR_CARD, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData,
            });
                
            if (!response.ok) {
                throw new Error('Échec de la création de la carte');
            }
            
            await response.json();
            toast.success('Carte Producteur créée avec succès!');
            loadData();
            RemiseZero();
        } catch (error) {
            toast.error('Erreur lors de la création de la carte');
        } finally {
            setLoading(false);
            URL.revokeObjectURL(previewImageProducteur);
        }
    };
            /** SETUP SELECT**/
            
    const handleSupplierSelect = (event) => {
    const producteurCardId = event.target.value;
    if (producteurCardId) {
        setSelectedProducteurCard(producteurCardId);

        const selectedProducteurCardObjectId = producteurCards.find(card => card.fournisseurId === producteurCardId)._id;
        setProducteurCardObjectId(selectedProducteurCardObjectId);
    }
    };

    useEffect(() => {
    const selectedProducteurCardData = producteurCards.find(card => card.fournisseurId === selectedProducteurCard);
    if (selectedProducteurCardData) {
        setNomProducteur(selectedProducteurCardData.nom);
        setAdresseProducteur(selectedProducteurCardData.adresse);
        setSiteProducteur(selectedProducteurCardData.siteWeb);
        setPreviewImageProducteur(selectedProducteurCardData.imageUrl);
    } else {
        setNomProducteur('');
        setAdresseProducteur('');
        setSiteProducteur('');
        setPreviewImageProducteur(null);
    }
    }, [selectedProducteurCard, producteurCards]);

                /* MODIFY */
    const handleSubmitModifySupplierCard = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('nom', nomProducteur);
        formData.append('adresse', adresseProducteur);
        formData.append('siteWeb', siteProducteur);
        formData.append('image', photoProducteur);

        setLoading(true);
        fetchWithAuth(`${API_ROUTES.PRODUCTEUR_CARD}/${producteurCardObjectId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        }, logout)
        .then(response => response.json())
        .then(() => {
            toast.success('Carte Producteur modifiée avec succès!');
            RemiseZero();
            loadData();
        })
        .catch(error => toast.error('Erreur lors de la modification de la carte producteur.'))
        .finally(() => 
            {setLoading(false);
            URL.revokeObjectURL(previewImageProducteur);
        });
    }

                /* DELETE */

    const handleSubmitDeleteSupplierCard = (e) => {
        e.preventDefault();

        
        const token = localStorage.getItem('token');
        
        setLoading(true);
        fetchWithAuth(`${API_ROUTES.PRODUCTEUR_CARD}/${producteurCardObjectId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
        }, logout)
        .then(response => {
            if (!response.ok) {
                throw new Error('La suppression de la carte producteur a échoué');
            }
            return response.json();
        })
        .then(data => {
            toast.success('La carte producteur a été supprimée avec succès!');
            loadData();
            RemiseZero();
        })
        .catch(error => toast.error('Erreur lors de la suppression de la carte producteur.'))
        .finally(() => {setLoading(false);
        });
};
        
    //* Gestion image article sélectionner + affichage *//

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto(file);
    
            const imageUrl = URL.createObjectURL(file);
            setPreviewImage(imageUrl);
        }
    };

    const handleFileProducteurChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            
            const imageUrl = URL.createObjectURL(file);
            
            setPhotoProducteur(file);
            setPreviewImageProducteur(imageUrl);
        }
    };
    
    
    //* Bouton remise à zéro de tous les forms *//

    const RemiseZero = () => {
        setNom('');
        setPrix('');
        setUnite('');
        setMesure('');
        setVrac(false);
        setLocal(false);
        setCategorie('');
        setDistributeur('');
        setDescription('')
        setComposition('');
        setPreviewImage(null);
        setPhoto(null);
        setSelectedCategory('');
        setCategoryName('');
        nouvelleCategorieRef.current.value='';
        setSelectedFournisseur('');
        setFournisseurName('');
        nouveauFournisseurRef.current.value='';
        setSelectedArticle('');
        setAdresseProducteur('');
        setSiteProducteur('');
        setPhotoProducteur(null);
        setPreviewImageProducteur(null);
        setSelectedProducteurCard('');
        URL.revokeObjectURL(previewImage);
        URL.revokeObjectURL(previewImageProducteur);
    }

    //*Gestion apparence Bouton Admin*//

    const toggleModaleContent = () => {
        setActiveBloc('Modale');
        setActiveButton1('');
        setActiveButton2('');
        setShowModaleContent(!showModaleContent);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowModifyArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowSupplyOptionButton(false);
            setShowProducteursContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowAddProducteursContent(false);
    };

    const toggleHoraireContent = () => {
        setActiveBloc('Horaire');
        setActiveButton1('');
        setActiveButton2('');
        setShowHoraireContent(!showHoraireContent);
            setShowModaleContent(false);
            setShowArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowSupplyOptionButton(false);
            setShowProducteursContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowAddProducteursContent(false);
    };

    const toggleArticleButton = () => {
        setActiveBloc('Article');
        setActiveButton1('');
        setActiveButton2('');
        setShowArticleButton(!showArticleButton);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowProducteursContent(false);
            setShowArticleContent(false);
            setShowArticleOptionButton(false);  
            setShowCategoryOptionButton(false);
            setShowSupplyOptionButton(false);
            setShowAddCategoryContent(false);  
            setShowModifyCategoryContent(false); 
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
            setShowAddProducteursContent(false);
    };

    const toggleArticleOptionButton = () => {
        setActiveButton1('ArticleButton');
        setActiveButton2('');
        setShowArticleOptionButton(!showArticleOptionButton);
            setShowCategoryOptionButton(false);
            setShowSupplyOptionButton(false);
            setShowArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowDeleteArticleContent(false);
    };

    const toggleAddArticleContent = () => {
        setActiveButton2('ajouterArticle');
        setShowArticleContent(!showArticleContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowProducteursContent(false);
            setShowModifyArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    };

    const toggleModifyArticleContent = () => {
        setActiveButton2('modifierArticle');
        setShowModifyArticleContent(!showModifyArticleContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowProducteursContent(false);
            setShowArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    };

    const toggleDeleteArticleContent = () => {
        setActiveButton2('supprimerArticle');
        setShowDeleteArticleContent(!showDeleteArticleContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowProducteursContent(false);
            setShowArticleContent(false);
            setShowModifyArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleCategoryOptionButton = () => {
        setActiveButton1('CategoryButton');
        setActiveButton2('');
        setShowCategoryOptionButton(!showCategoryOptionButton);
            setShowSupplyOptionButton(false);
            setShowArticleOptionButton(false);
            setShowArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
    };

    const toggleAddCategoryContent = () => {
        setActiveButton2('ajouterCategorie');
        setShowAddCategoryContent(!showAddCategoryContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleModifyCategoryContent = () => {
        setActiveButton2('modifierCategorie');
        setShowModifyCategoryContent(!showModifyCategoryContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowAddCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleDeleteCategoryContent = () => {
        setActiveButton2('supprimerCategorie');
        setShowDeleteCategoryContent(!showDeleteCategoryContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleSupplyOptionButton = () => {
        setActiveButton1('SupplyButton');
        setActiveButton2('');
        setShowSupplyOptionButton(!showSupplyOptionButton);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowArticleContent(false);
            setShowAddCategoryContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
    };

    const toggleAddFournisseurContent = () => {
        setActiveButton2('ajouterFournisseur');
        setShowAddFournisseurContent(!showAddFournisseurContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddCategoryContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleModifyFournisseurContent = () => {
        setActiveButton2('modifierFournisseur');
        setShowModifyFournisseurContent(!showModifyFournisseurContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    
    const toggleDeleteFournisseurContent = () => {
        setActiveButton2('supprimerFournisseur');
        setShowDeleteFournisseurContent(!showDeleteFournisseurContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowProducteursContent(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    }

    const toggleProducteursContent = () => {
        setActiveBloc('Producteurs');
        setActiveButton1('');
        setActiveButton2('');
        setShowProducteursContent(!showProducteursContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowAddCategoryContent(false);
            setShowSupplyOptionButton(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
            setShowAddProducteursContent(false);
    };

    const toggleAddProducteursContent = () => {
        setActiveButton1('');
        setActiveButton2('ajouterProducteurs');
        setShowAddProducteursContent(!showAddProducteursContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowAddCategoryContent(false);
            setShowSupplyOptionButton(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowDeleteProducteursContent(false);
    };

    const toggleModifyProducteursContent = () => {
        setActiveButton1('');
        setActiveButton2('modifierProducteurs');
        setShowModifyProducteursContent(!showModifyProducteursContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowAddCategoryContent(false);
            setShowSupplyOptionButton(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowAddProducteursContent(false);
            setShowDeleteProducteursContent(false);
    };

    const toggleDeleteProducteursContent = () => {
        setActiveButton1('');
        setActiveButton2('supprimerProducteurs');
        setShowDeleteProducteursContent(!showDeleteProducteursContent);
            setShowModaleContent(false);
            setShowHoraireContent(false);
            setShowArticleContent(false);
            setShowArticleButton(false);
            setShowArticleOptionButton(false);
            setShowCategoryOptionButton(false);
            setShowAddCategoryContent(false);
            setShowSupplyOptionButton(false);
            setShowModifyCategoryContent(false);
            setShowDeleteCategoryContent(false);
            setShowAddFournisseurContent(false);
            setShowModifyFournisseurContent(false);
            setShowDeleteFournisseurContent(false);
            setShowModifyArticleContent(false);
            setShowDeleteArticleContent(false);
            setShowModifyProducteursContent(false);
            setShowAddProducteursContent(false);
    };

    return (
        <div className='admin-container'>
            <ToastContainer />
            {loading &&
            <div className='loading__div'>
                <TailSpin color="#e9afaf" className="loading-icon" />
            </div>}
            <div className='titre-bloc-container'>
                <h1 className={`titre-bloc ${activeBloc === 'Modale' ? 'active' : ''}`} onClick={toggleModaleContent}>Pop-Up</h1>
                <h1 className={`titre-bloc ${activeBloc === 'Horaire' ? 'active' : ''}`} onClick={toggleHoraireContent}>Horaires</h1>
                <h1 className={`titre-bloc ${activeBloc === 'Article' ? 'active' : ''}`} onClick={toggleArticleButton}>Articles</h1>
                <h1 className={`titre-bloc ${activeBloc === 'Producteurs' ? 'active' : ''}`} onClick={toggleProducteursContent}>Producteurs</h1>
            </div>
            <div className={`admin-article-button-container ${showArticleButton ? 'openArticleButton' : 'closeArticleButton'}`}> {/*afficher les boutons globaux (article/catégorie/distributeur/restart)*/}
                    <div className='admin-article-button__div'>
                        <div className={`admin-article-button ${activeButton1 === 'ArticleButton' ? 'activeButton1' : ''}`}
                        onClick={toggleArticleOptionButton}>
                            <FontAwesomeIcon icon={faStore} className='article-button-icon'/>
                            <span className='article-button-text'>
                                Produit
                            </span>
                        </div>
                        <div className={`admin-article-button ${activeButton1 === 'CategoryButton' ? 'activeButton1' : ''} `}
                        onClick={toggleCategoryOptionButton}>
                            <FontAwesomeIcon icon={faTag} className='article-button-icon'/>
                            <span className='article-button-text'>
                                Catégorie
                            </span>
                        </div>
                        <div className={`admin-article-button ${activeButton1 === 'SupplyButton' ? 'activeButton1' : ''} `}
                        onClick={toggleSupplyOptionButton}>
                            <FontAwesomeIcon icon={faTruckFast} className='article-button-icon'/>
                            <span className='article-button-text'>
                                Fournisseur
                            </span>
                        </div>
                        <div className='admin-article-button remise-zero' onClick={RemiseZero}>
                        <FontAwesomeIcon icon={faArrowRotateLeft} className='article-button-icon'/>
                        </div>
                    </div>
                    <div className='article-button__div'> {/* bouton ajouter/modifier/supprimer un article*/}
                        <div className={`article-button 
                            ${showArticleOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'ajouterArticle' ? 'activeButton2' : ''}`}
                            onClick={() => {
                                toggleAddArticleContent();
                                RemiseZero();
                            }}>
                            <FontAwesomeIcon icon={faPlus} className='article-button-icon'/>
                        </div>
                        <div className={`article-button
                            ${showArticleOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'modifierArticle' ? 'activeButton2' : ''}`}
                            onClick={() => {
                                toggleModifyArticleContent();
                                RemiseZero();
                            }}>
                            <FontAwesomeIcon icon={faPen} className='article-button-icon'/>
                        </div>
                        <div className={`article-button
                            ${showArticleOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'supprimerArticle' ? 'activeButton2' : ''}`}
                            onClick={() => {
                                toggleDeleteArticleContent();
                                RemiseZero();
                            }}>
                            <FontAwesomeIcon icon={faTrash} className='article-button-icon'/>
                        </div>
                    </div>
                    <div className='article-button__div'> {/* bouton ajouter/modifier/supprimer une catégorie*/}
                        <div className={`article-button 
                            ${showCategoryOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'ajouterCategorie' ? 'activeButton2' : ''}`}
                            onClick={toggleAddCategoryContent}>
                            <FontAwesomeIcon icon={faPlus} className='article-button-icon'/>
                        </div>
                        <div className={`article-button
                            ${showCategoryOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'modifierCategorie' ? 'activeButton2' : ''}`}
                            onClick={toggleModifyCategoryContent}>
                            <FontAwesomeIcon icon={faPen} className='article-button-icon'/>
                        </div>
                        <div className={`article-button
                            ${showCategoryOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'supprimerCategorie' ? 'activeButton2' : ''}`}
                            onClick={toggleDeleteCategoryContent}>
                            <FontAwesomeIcon icon={faTrash} className='article-button-icon'/>
                        </div>
                    </div>
                    <div className='article-button__div'> {/* bouton ajouter/modifier/supprimer un fournisseur*/}
                        <div className={`article-button 
                            ${showSupplyOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'ajouterFournisseur' ? 'activeButton2' : ''}`}
                            onClick={toggleAddFournisseurContent}>
                            <FontAwesomeIcon icon={faPlus} className='article-button-icon'/>
                        </div>
                        <div className={`article-button 
                            ${showSupplyOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'modifierFournisseur' ? 'activeButton2' : ''}`}
                            onClick={toggleModifyFournisseurContent}>
                            <FontAwesomeIcon icon={faPen} className='article-button-icon'/>
                        </div>
                        <div className={`article-button
                            ${showSupplyOptionButton ? 'openButton' : 'closeButton'}
                            ${activeButton2 === 'supprimerFournisseur' ? 'activeButton2' : ''}`}
                            onClick={toggleDeleteFournisseurContent}>
                            <FontAwesomeIcon icon={faTrash} className='article-button-icon'/>
                        </div>
                    </div>
            </div>
            <div className={`admin-article-button-container ${showProducteursContent ? 'openArticleButton' : 'closeArticleButton'}`}> {/*afficher les boutons Producteurs (ajouter/modifier/supprimer/restart)*/}
                <div className='article-button__div'> {/* bouton ajouter/modifier/supprimer un article*/}
                    <div className={`article-button 
                        ${activeButton2 === 'ajouterProducteurs' ? 'activeButton2' : ''}`}
                        onClick={() => {
                            toggleAddProducteursContent();
                            RemiseZero();
                        }}>
                        <FontAwesomeIcon icon={faPlus} className='article-button-icon'/>
                    </div>
                    <div className={`article-button
                        ${activeButton2 === 'modifierProducteurs' ? 'activeButton2' : ''}`}
                        onClick={() => {
                            toggleModifyProducteursContent();
                            RemiseZero();
                        }}>
                        <FontAwesomeIcon icon={faPen} className='article-button-icon'/>
                    </div>
                    <div className={`article-button
                        ${activeButton2 === 'supprimerProducteurs' ? 'activeButton2' : ''}`}
                        onClick={() => {
                            toggleDeleteProducteursContent();
                            RemiseZero();
                        }}>
                        <FontAwesomeIcon icon={faTrash} className='article-button-icon'/>
                    </div>
                    <div className='admin-article-button remise-zero' onClick={RemiseZero}>
                        <FontAwesomeIcon icon={faArrowRotateLeft} className='article-button-icon'/>
                    </div>
                </div>
            </div>
            <div className='form-container'>
                <form onSubmit={handleSubmitModale} className='bloc-modale'> {/*Modifier la modaleOuverture*/}
                        <div className={`admin-modaleOuverture-dropdown ${showModaleContent ? 'open' : 'close'}`}>
                        <div className='admin-modaleOuverture'>
                            <div className='admin-modal-content'>
                                <div className='admin-cloche-cross__icon'>
                                    <FontAwesomeIcon icon={faBell} className='admin-cloche-icon' />
                                </div>
                                <h1 className='admin-modaleOuverture__h1'> En ce moment chez BAsique !</h1>
                                <div className='admin-modale-form'>
                                    <textarea
                                        ref={titreRef}
                                        className='admin-modaleOuverture__title'
                                        value={titre}
                                        onChange={handleTextareaTitleChange}
                                    />
                                    <textarea
                                        ref={messageRef}
                                        className='admin-modaleOuverture__message'
                                        value={message}
                                        onChange={handleTextareaMessageChange}
                                    />
                                </div>
                            </div>        
                        </div>
                        <button type="submit" className="admin-submit">Mettre à jour</button>
                    </div>
                </form>
                <form onSubmit={handleSubmitHoraires} className='bloc-horaires'> {/*Modifier les horaire*/}
                <div className={`admin-horaire-dropdown ${showHoraireContent ? 'open' : 'close'}`}>
                <div className='admin-bandeau-presentation'>
                            <p className='adresse'>
                                17 rue Anatole France | Roanne
                            </p>
                            <textarea
                                ref={horaireRef}
                                className='admin-horaire-textarea'
                                value={horaire}
                                onChange={handleTextareaHoraireChange}
                            />
                        </div>
                        <div className='legende-container'>
                            <p className='legende'>Pour mettre en gras : /*mot*/</p>
                            <p className='legende'>Pour mettre en italique : //mot//</p>
                        </div>
                        <button type="submit" className="admin-submit">Mettre à jour</button>
                </div>
                </form>
                <form onSubmit={handleSubmitAddArticles} className='bloc-article'> {/*Ajouter des articles*/}
                    <div className={`admin-article-dropdown ${showArticleContent ? 'open' : 'close'}`}>
                        <div className='admin-article-container'>
                            <div className='recto-article-container'>
                            <div className='recto-article'>
                                <div className='admin-article-bloc-photo'>
                                <div className={`admin-article-addPhoto ${errors.photo ? 'error' : ''}`}>
                                    <label htmlFor='photo-input' className='photo-label'>
                                        <input 
                                            type='file' 
                                            id='photo-input' 
                                            accept='image/png, image/jpeg' 
                                            onChange={handleFileChange} 
                                            className='photo-input' 
                                            name='image'/>
                                        {previewImage ? (
                                            <img src={previewImage} alt='Preview' className='preview-image' />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faImage} className='addPhoto-icon' />
                                                <span className="photo-text">Ajouter une photo</span>
                                            </>
                                        )}
                                    </label>
                                </div>
                                    <div className='admin-article-vrac-local'>
                                        <div className='admin-article-vrac'>
                                            <button type='button' onClick={() => setVrac(!vrac)} className={vrac ? 'selected' : ''}>Vrac</button>
                                        </div>
                                        <div className='admin-article-local'>
                                            <button type='button' onClick={() => setLocal(!local)} className={local ? 'selected' : ''}>Local</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='admin-article-nom'>
                                    <input type="text" 
                                        value={nom} onChange={(e) => setNom(e.target.value)} 
                                        ref={nomRef} placeholder="Nom de l'article" 
                                        className={errors.nom ? 'error' : ''}
                                    />
                                </div>
                                <div className='admin-article-prix'>
                                    <input 
                                        type="number" 
                                        value={prix} onChange={(e) => setPrix(e.target.value)} 
                                        ref={prixRef} placeholder="Prix" 
                                        className={errors.prix ? 'error' : ''}
                                    />/

                                    <input 
                                        type='number' 
                                        value={mesure} onChange={(e) => setMesure(e.target.value)} 
                                        placeholder='Mesure'
                                        className={errors.mesure ? 'error' : ''}
                                    />
                                    <select value={unite} onChange={(e) => setUnite(e.target.value)} className={errors.unite ? 'error' : ''}>
                                        <option value=''></option>
                                        <option value="unité">Unité</option>
                                        <option value="g">g</option>
                                        <option value="ml">ml</option>
                                    </select>                            
                                </div>
                                <div className='infoSupp'>+ d'infos</div>
                            </div>
                            </div>
                            <div className='verso-article-container'>
                            <div className='admin-verso-article'>
                                <div className='admin-article-categorie-distributeur'>
                                    <div className='admin-article-categorie'>
                                        <select 
                                            value={categorie} 
                                            onChange={handleCategorieChange}
                                            className={errors.categorie ? 'error' : ''}
                                        >
                                            <option value="">Catégories</option>
                                            {categories.map(category => (
                                                <option key={category._id} value={category._id}>
                                                    {category.categorie}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='admin-article-distributeur'>
                                        <select 
                                            value={distributeur} 
                                            onChange={handleDistributeurChange}
                                            className={errors.distributeur ? 'error' : ''}
                                        >
                                            <option value="">Fournisseurs</option>
                                            {fournisseurs.map(fournisseur => (
                                                <option key={fournisseur._id} value={fournisseur._id}>
                                                    {fournisseur.fournisseur}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='admin-article-add-modify-description'>
                                    <textarea 
                                        value={description} 
                                        onChange={handleDescriptionChange} 
                                        placeholder="Description de l'article"
                                        className={errors.description ? 'error' : ''}
                                    />
                                </div>
                                <div className='admin-article-add-modify-composition'>
                                    <textarea 
                                        value={composition} 
                                        onChange={handleCompositionChange} 
                                        placeholder="Composition de l'article"
                                        className={errors.composition ? 'error' : ''}
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                        <span className='info-g-ml'>*Pour afficher "Kg" ou "L", écrire "1000" dans mesure pour "g" ou "ml"</span>
                        <span className='info-vrac-local'>*Cliquer sur les boutons vrac et local pour les activer</span>
                        <button type="submit" className="admin-submit">Ajouter</button>
                    </div>
                </form>
                <form onSubmit={handleSubmitModifyArticles} className={`bloc-article-modifier-supprimer ${showModifyArticleContent ? 'open' : 'close'}`}> {/*Modifier des articles*/}
                    <div className='admin-article-modifier-supprimer'>
                        <div className='admin-article-select__div'>
                            <select
                                className='admin-article__select'
                                value={selectedArticle}
                                onChange={handleArticleSelect}>
                                <option value="">Article à modifier</option>
                                {articles.map(article => (
                                    <option key={article._id} value={article._id}>
                                        {article.nom}
                                    </option>
                                ))}
                            </select>
                            {selectedArticle && (
                                <div className='article-dropdown'>
                                <div className='admin-article-container'>
                                    <div className='recto-article-container'>
                                    <div className='recto-article'>
                                        <div className='admin-article-bloc-photo'>
                                        <div className='admin-article-addPhoto'>
                                            <label htmlFor='photo-input' className='photo-label'>
                                                <input type='file' id='photo-input' accept='image/png, image/jpeg' onChange={handleFileChange} className='photo-input' name='image'/>
                                                {previewImage ? (
                                                    <img src={previewImage} alt='Preview' className='preview-image' />
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon={faImage} className='addPhoto-icon' />
                                                        <span className="photo-text">Ajouter une photo</span>
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                            <div className='admin-article-vrac-local'>
                                                <div className='admin-article-vrac'>
                                                    <button type='button' onClick={() => setVrac(!vrac)} className={vrac ? 'selected' : ''}>Vrac</button>
                                                </div>
                                                <div className='admin-article-local'>
                                                    <button type='button' onClick={() => setLocal(!local)} className={local ? 'selected' : ''}>Local</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='admin-article-nom'>
                                            <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} ref={nomRef} placeholder="Nom de l'article" />   
                                        </div>
                                        <div className='admin-article-prix'>
                                            <input type="number" value={prix} onChange={(e) => setPrix(e.target.value)} ref={prixRef} placeholder="Prix" />/
                                            <input type='number' value={mesure} onChange={(e) => setMesure(e.target.value)} placeholder='Mesure'/>
                                            <select value={unite} onChange={(e) => setUnite(e.target.value)}>
                                                <option value=''> </option>
                                                <option value="unité">Unité</option>
                                                <option value="g">g</option>
                                                <option value="ml">ml</option>
                                            </select>                            
                                        </div>
                                        <div className='infoSupp'>+ d'infos</div>
                                    </div>
                                    </div>
                                    <div className='verso-article-container'>
                                    <div className='admin-verso-article'>
                                        <div className='admin-article-categorie-distributeur'>
                                            <div className='admin-article-categorie'>
                                                <select 
                                                    value={categorie} 
                                                    onChange={handleCategorieChange}>
                                                    <option value="">Catégories</option>
                                                    {categories.map(category => (
                                                        <option key={category._id} value={category._id}>
                                                            {category.categorie}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='admin-article-distributeur'>
                                                <select 
                                                    value={distributeur} 
                                                    onChange={handleDistributeurChange}>
                                                    <option value="">Fournisseurs</option>
                                                    {fournisseurs.map(fournisseur => (
                                                        <option key={fournisseur._id} value={fournisseur._id}>
                                                            {fournisseur.fournisseur}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='admin-article-add-modify-description'>
                                            <textarea value={description} onChange={handleDescriptionChange} placeholder="Description de l'article"></textarea>
                                        </div>
                                        <div className='admin-article-add-modify-composition'>
                                            <textarea value={composition} onChange={handleCompositionChange} placeholder="Composition de l'article"></textarea>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <button type="submit" className="admin-submit">Modifier</button>
                            </div>
                            )}
                        </div>
                    </div>
                </form>
                <form onSubmit={handleSubmitDeleteArticles} className={`bloc-article-modifier-supprimer ${showDeleteArticleContent ? 'open' : 'close'}`}> {/*Supprimer des articles*/}
                    <div className='admin-article-modifier-supprimer'>
                        <div className='admin-article-select__div'>
                            <select
                                className='admin-article__select'
                                value={selectedArticle}
                                onChange={handleArticleSelect}>
                                <option value="">Article à supprimer</option>
                                {articles.map(article => (
                                    <option key={article._id} value={article._id}>
                                        {article.nom}
                                    </option>
                                ))}
                            </select>
                            {selectedArticle && (
                                <div className='article-dropdown'>
                                <div className='admin-article-container'>
                                    <div className='recto-article-container'>
                                    <div className='recto-article'>
                                        <div className='admin-article-bloc-photo'>
                                        <div className='article-addPhoto'>
                                            <div className='photo-div'>
                                                    <img src={previewImage} alt='Preview' className='preview-image' />
                                            </div>
                                        </div>
                                        <div className='article-vrac-local'>
                                            <div className='article-vrac'>
                                                {vrac ? <img src="/assets/vrac-icon.webp" alt="Vrac" className='vrac-logo'/> : null}    
                                            </div>
                                            <div className='article-local'>
                                                {local ? <img src="/assets/local-icon.webp" alt="Local" className='local-logo'/> : null}                                            </div>
                                            </div> 
                                        </div>
                                        <div className='article-nom'>
                                            {nom}
                                        </div>
                                        <div className='article-prix'>
                                            {prix}€ / {UnitforCardArticle(mesure, unite)}                       
                                        </div>
                                        <div className='infoSupp'>+ d'infos</div>
                                    </div>
                                    </div>
                                    <div className='verso-article-container'>
                                    <div className='admin-verso-article'>
                                        <div className='admin-article-categorie-distributeur'>
                                            <div className='admin-article-categorie'>
                                                {deleteCategorie} 
                                            </div>
                                            <div className='admin-article-distributeur'>
                                                {deleteDistributeur} 
                                            </div>
                                        </div>
                                        <div className='admin-article-description'>
                                            {description}
                                        </div>
                                        <div className='admin-article-composition'>
                                            <span>Composition</span>{composition}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <button type="submit" className="admin-submit">Supprimer</button>
                            </div>
                            )}
                        </div>
                    </div>
                </form>
                <form onSubmit={handleSubmitAddCategory} className={`bloc-categorie-fournisseur ${showAddCategoryContent ? 'open' : 'close'}`}> {/*Ajouter une catégorie pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <input
                            className='nouvelle-categorie-fournisseur__input'
                            type='text'
                            placeholder='Nouvelle catégorie'
                            ref={nouvelleCategorieRef}
                        />
                        <button type="submit" className="admin-submit">Ajouter</button>
                    </div>
                </form>
                <form onSubmit={handleSubmitModifyCategory} className={`bloc-categorie-fournisseur ${showModifyCategoryContent ? 'open' : 'close'}`}> {/*Modifier une catégorie pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <div className='admin-select-input'>
                            <select 
                                className='admin-categorie-fournisseur-select'
                                value={selectedCategory} 
                                onChange={handleCategorySelect}>
                                <option value="">Catégorie à modifier</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>
                                        {category.categorie}
                                    </option>
                                ))}
                            </select>
                            {selectedCategory && (
                            <input
                                className='admin-categorie-fournisseur-input'
                                type="text"
                                value={categoryName}
                                onChange={handleCategoryNameChange}
                            />
                            )}
                    </div>
                        <button type='submit' className='admin-submit'>Modifier</button>
                    </div>            
                </form>
                <form onSubmit={handleSubmitDeleteCategory} className={`bloc-categorie-fournisseur ${showDeleteCategoryContent ? 'open' : 'close'}`}> {/*Supprimer une catégorie pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <select 
                            className='admin-delete-select'
                            value={selectedCategory}
                            onChange={handleCategorySelect}>
                            <option value="">Catégorie à supprimer</option>
                            {categories.map(category => (
                                <option key={category._id} value={category._id}>
                                    {category.categorie}
                                </option>
                            ))}
                        </select>
                        <button type='submit' className='admin-submit'>Supprimer</button>
                    </div> 
                </form>
                <form onSubmit={handleSubmitAddSupplier} className={`bloc-categorie-fournisseur ${showAddFournisseurContent ? 'open' : 'close'}`}> {/*Ajouter un fournisseur pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <input
                            className='nouvelle-categorie-fournisseur__input'
                            type='text'
                            placeholder='Nouveau fournisseur'
                            ref={nouveauFournisseurRef}
                        />
                        <button type="submit" className="admin-submit">Ajouter</button>
                    </div>
                </form>
                <form onSubmit={handleSubmitModifySupplier} className={`bloc-categorie-fournisseur ${showModifyFournisseurContent ? 'open' : 'close'}`}> {/*Modifier un fournisseur pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <div className='admin-select-input'>
                            <select 
                                className='admin-categorie-fournisseur-select'
                                value={selectedFournisseur} 
                                onChange={handleFournisseurSelect}>
                                <option value="">Fournisseur à modifier</option>
                                {fournisseurs.map(fournisseur => (
                                    <option key={fournisseur._id} value={fournisseur._id}>
                                        {fournisseur.fournisseur}
                                    </option>
                                ))}
                            </select>
                            {selectedFournisseur && (
                            <input
                                className='admin-categorie-fournisseur-input'
                                type="text"
                                value={fournisseurName}
                                onChange={handleFournisseurNameChange}
                            />
                            )}
                        </div>
                        <button type='submit' className='admin-submit'>Modifier</button>
                    </div>   
                </form>
                <form onSubmit={handleSubmitDeleteSupplier} className={`bloc-categorie-fournisseur ${showDeleteFournisseurContent ? 'open' : 'close'}`}> {/*Supprimer un fournisseur pour les articles*/}
                    <div className='admin-categorie-fournisseur-dropdown'>
                        <select 
                            className='admin-delete-select'
                            value={selectedFournisseur}
                            onChange={handleFournisseurSelect}>
                            <option value="">Fournisseur à supprimer</option>
                            {fournisseurs.map(fournisseur => (
                                <option key={fournisseur._id} value={fournisseur._id}>
                                    {fournisseur.fournisseur}
                                </option>
                            ))}
                        </select>
                        <button type='submit' className='admin-submit'>Supprimer</button>
                    </div> 
                </form>
                <form onSubmit={handleSubmitAddSupplierCard} className={`bloc-supplierCard ${showAddProducteursContent ? 'open' : 'close'}`}> {/*Ajouter une carte fournisseur*/}
                    <div className='supplierCard-container'>
                    <select 
                        className='admin-article__select'
                        value={selectedFournisseur} 
                        onChange={handleFournisseurSelect}>
                        <option value="">Créer une carte producteur</option>
                        {fournisseursSansCarteProducteur.map(fournisseur => (
                            <option key={fournisseur._id} value={fournisseur._id}>
                                {fournisseur.fournisseur}
                            </option>
                        ))}
                    </select>
                    {selectedFournisseur && (
                        <div className='admin-producteur-container'>
                        <div className='admin-producteur'>
                            <div className='admin-producteur__div'>
                                <div className='admin-producteur-photo-nom'>
                                <div className={`admin-producteur-photo ${errors.photoProducteur ? 'error' : ''}`}>
                                        <label htmlFor='photoProducteur-input' className='photo-label'>
                                            <input 
                                                type='file' 
                                                id='photoProducteur-input' 
                                                accept='image/png, image/jpeg' 
                                                onChange={handleFileProducteurChange} 
                                                className='photo-input' 
                                                name='image'/>
                                            {previewImageProducteur ? (
                                                <img src={previewImageProducteur} alt='Preview' className='preview-imageProducteur' />
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faImage} className='addPhoto-icon' />
                                                    <span className="photo-text">Ajouter une photo</span>
                                                </>
                                            )}
                                        </label>
                                    </div>
                                    <div className='admin-producteur-nom-input'>
                                        <input type="text" 
                                            value={nomProducteur} 
                                            readOnly={true}
                                            className={errors.nomProducteur ? 'error' : ''}
                                        />
                                    </div>
                                </div>
                                <div className='admin-producteur-adresse-site'>
                                    <div className='admin-producteur-adresse'>
                                        <textarea type="text" 
                                            value={adresseProducteur} onChange={(e) => setAdresseProducteur(e.target.value)} 
                                            ref={adresseProducteurRef} placeholder="Adresse du producteur" 
                                            className={errors.adresseProducteur ? 'error' : ''}
                                        />                            
                                    </div>
                                    <div className='admin-producteur-site'>
                                        <input type="text" 
                                            value={siteProducteur} onChange={(e) => setSiteProducteur(e.target.value)} 
                                            ref={siteProducteurRef} placeholder="Site Web du producteur" 
                                            className={errors.siteProducteur ? 'error' : ''}
                                        />                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="admin-submit">Ajouter</button>
                        </div>
                    )}
                    </div>
                </form>
                <form onSubmit={handleSubmitModifySupplierCard} className={`bloc-supplierCard ${showModifyProducteursContent ? 'open' : 'close'}`}> {/*Modifier une carte fournisseur*/}
                <div className='supplierCard-container'>
                    <select 
                        className='admin-article__select'
                        value={selectedProducteurCard} 
                        onChange={handleSupplierSelect}>
                        <option value="">Modifier une carte producteur</option>
                        {fournisseursAvecCarteProducteur.map(fournisseur => (
                            <option key={fournisseur._id} value={fournisseur._id}>
                                {fournisseur.fournisseur}
                            </option>
                        ))}
                    </select>
                    {selectedProducteurCard && (
                        <div className='admin-producteur-container'>
                        <div className='admin-producteur'>
                            <div className='admin-producteur__div'>
                                <div className='admin-producteur-photo-nom'>
                                    <div className='admin-producteur-photo'>
                                        <label htmlFor='photoProducteur-input' className='photo-label'>
                                            <input 
                                                type='file' 
                                                id='photoProducteur-input' 
                                                accept='image/png, image/jpeg' 
                                                onChange={handleFileProducteurChange} 
                                                className='photo-input' 
                                                name='image'/>
                                            {previewImageProducteur ? (
                                                <img src={previewImageProducteur} alt='Preview' className='preview-imageProducteur' />
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faImage} className='addPhoto-icon' />
                                                    <span className="photo-text">Ajouter une photo</span>
                                                </>
                                            )}
                                        </label>
                                    </div>
                                    <div className='admin-producteur-nom-input'>
                                    <input type="text" 
                                            value={nomProducteur} onChange={(e) => setNomProducteur(e.target.value)} 
                                            ref={nomProducteurRef} placeholder="Nom du producteur" 
                                            className={errors.nomProducteur ? 'error' : ''}
                                            readOnly={true}
                                        />                           
                                    </div>
                                </div>
                                <div className='admin-producteur-adresse-site'>
                                    <div className='admin-producteur-adresse'>
                                        <textarea type="text" 
                                            value={adresseProducteur} onChange={(e) => setAdresseProducteur(e.target.value)} 
                                            ref={adresseProducteurRef} placeholder="Adresse du producteur" 
                                            className={errors.adresseProducteur ? 'error' : ''}
                                        />                            
                                    </div>
                                    <div className='admin-producteur-site'>
                                        <input type="text" 
                                            value={siteProducteur} onChange={(e) => setSiteProducteur(e.target.value)} 
                                            ref={siteProducteurRef} placeholder="Site Web du producteur" 
                                            className={errors.siteProducteur ? 'error' : ''}
                                        />                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="admin-submit">Modifier</button>
                        </div>
                    )}
                    </div>            
                </form>
                <form onSubmit={handleSubmitDeleteSupplierCard} className={`bloc-supplierCard ${showDeleteProducteursContent ? 'open' : 'close'}`}> {/*Supprimer une carte fournisseur*/}
                <div className='supplierCard-container'>
                    <select 
                        className='admin-article__select'
                        value={selectedProducteurCard} 
                        onChange={handleSupplierSelect}>
                        <option value="">Supprimer une carte producteur</option>
                        {fournisseursAvecCarteProducteur.map(fournisseur => (
                            <option key={fournisseur._id} value={fournisseur._id}>
                                {fournisseur.fournisseur}
                            </option>
                        ))}
                    </select>
                    {selectedProducteurCard && (
                        <div className='admin-producteur-container'>
                        <div className='admin-producteur'>
                            <div className='admin-producteur__div'>
                                <div className='admin-producteur-photo-nom'>
                                    <div className='admin-producteur-photo'>
                                        <img src={previewImageProducteur} alt={previewImageProducteur}/>
                                    </div>
                                    <div className='admin-producteur-nom'>
                                        {nomProducteur}
                                    </div>
                                </div>
                                <div className='admin-producteur-adresse-site'>
                                    <div className='admin-producteur-adresse'>
                                        {adresseProducteur}                           
                                    </div>
                                    <div className='admin-producteur-site'>
                                        {siteProducteur}                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="admin-submit">Supprimer</button>
                        </div>
                    )}
                </div>
                </form>
            </div>
        </div>
    );
}

export default Admin;
