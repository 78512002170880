import Footer from './components/Footer/Footer';
import NotFound from './pages/NotFound/NotFound';
import EnConstruction from './pages/EnConstruction/EnConstruction';
import Accessibility from './pages/Accessibility/Accessibility';
import Home from './pages/Home/Home';
import Confidentiality from './pages/Confidentiality/Confidentiality';
import SiteMap from './pages/SiteMap/SiteMap';
import Header from './components/Header/Header';
import Login from './pages/Login/Login';
import Articles from './pages/Articles/Articles';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PrivateRouteWorking from './components/PrivateRouteWorking/PrivateRouteWorking';
import Admin from './pages/Admin/Admin';
import Producteurs from './pages/Producteurs/Producteurs';
import { APP_ROUTES } from './utils/constants';
import { AuthProvider } from './AuthContext';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

  return (
    < AuthProvider >
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path={APP_ROUTES.EPICERIE} element={<EnConstruction />} />
          <Route path={APP_ROUTES.PRODUCTEURS} element={<PrivateRouteWorking><Producteurs /></PrivateRouteWorking>} />
          <Route path={APP_ROUTES.PRODUITS} element={<PrivateRouteWorking><Articles /></PrivateRouteWorking>} />
          <Route path={APP_ROUTES.ACCESSIBILITY} element={<Accessibility />} />
          <Route path={APP_ROUTES.CONFIDENTIALITY} element={<Confidentiality />} />
          <Route path={APP_ROUTES.SITEMAP} element={<SiteMap />} />
          <Route path={APP_ROUTES.LOGIN} element={<Login />} />
          <Route path={APP_ROUTES.ADMIN} element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path={APP_ROUTES.WORKING} element={<EnConstruction/>}/>
          <Route path ="*" element={<NotFound />} /> {/* Route par défaut si aucune autre route ne correspond */}
        </Routes>
        <Footer />
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
