import React, { useState, useEffect } from 'react';
import CardArticle from '../../components/CardArticle/CardArticle';
import { API_ROUTES } from '../../utils/constants';
import './Articles.scss';

function Articles() {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorieFiltre, setCategorieFiltre] = useState('Tous');
  const [filtreVrac, setFiltreVrac] = useState(false);
  const [filtreLocal, setFiltreLocal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 9;
  

  useEffect(() => {
    fetch(API_ROUTES.ARTICLE)
    .then(response => response.json())
    .then(data => {
      const sortedArticles = data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setArticles(sortedArticles);
    })
    .catch(error => console.error('Error fetching articles:', error));

      fetch(API_ROUTES.CATEGORIE)
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Erreur lors du chargement des catégories', error));  
    }, []);

  const filtrerArticles = () => {
    let articlesFiltres = [...articles];

    if (categorieFiltre !== 'Tous') {
      articlesFiltres = articlesFiltres.filter(article => article.categorie === categorieFiltre._id);
    }

    if (filtreVrac) {
      articlesFiltres = articlesFiltres.filter(article => article.vrac);
    }

    if (filtreLocal) {
      articlesFiltres = articlesFiltres.filter(article => article.local);
    }

    return articlesFiltres;
  };

const handleFilterChange = (value, filterType) => {
  setCurrentPage(1);
  if (filterType === 'categorie') {
    if (value === '' || value === 'Tous') {
      setCategorieFiltre('Tous');
    } else {
      const category = typeof value === 'string' ? categories.find(cat => cat._id === value) : value;
      setCategorieFiltre(category);
    }
  } else if (filterType === 'vrac' || filterType === 'local') {
    const newValue = value === 'true' ? true : value === 'false' ? false : value;
    if (filterType === 'vrac') {
      setFiltreVrac(newValue);
    } else if (filterType === 'local') {
      setFiltreLocal(newValue);
    }
  }
};


  return (
    <div className="articles-page">
      <div className='filtre-article__div'>
        <div className='filtre-categorie-vrac'>
          <h1 className='filtre__h1'>Filtres</h1>
          <div className='filtre-categorie'>
            <div className='filtre-tous-button'>
              <button
                onClick={() => handleFilterChange('Tous', 'categorie')}
                className={categorieFiltre === 'Tous' ? 'active' : ''}
              >
                Tous
              </button>
            </div>
            <div className='filtre-categorie-button'>
              {categories.map(category => (
                <button
                  key={category._id}
                  onClick={() => handleFilterChange(category, 'categorie')}
                  className={categorieFiltre._id === category._id ? 'active' : ''}
                >
                  {category.categorie}
                </button>

              ))}
            </div>
            <div className='filtre-categorie-select'>
            <select
              onChange={(e) => handleFilterChange(e.target.value, 'categorie')}
              value={categorieFiltre?._id || ''}
            >
              <option value="">Sélectionnez une catégorie</option>
              {categories.map(category => (
                <option key={category._id} value={category._id}>
                  {category.categorie}
                </option>
              ))}
            </select>
            </div>
          </div>
          <div className='filtre-vrac-local'>
            <div className='filtre-vrac'>
            <h2 className='filtre__h2'>Vrac</h2>
            <label className="switch">
              <input className='desactive' type="checkbox" checked={filtreVrac} onChange={() => handleFilterChange(!filtreVrac, 'vrac')} />
              <span className="slider"></span>
            </label>
            </div>
            <div className='filtre-local'>
            <h2 className='filtre__h2'>Local</h2>
            <label className="switch">
              <input className='desactive' type="checkbox" checked={filtreLocal} onChange={() => handleFilterChange(!filtreLocal, 'local')} />
              <span className="slider"></span>
            </label>
            </div>
          </div>
        </div>
      </div>
      <div className='articles__div'>
        <CardArticle 
          articles={filtrerArticles()}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          articlesPerPage={articlesPerPage}
        />
      </div>
    </div>
  );
}


export default Articles;
