import React, { useState, useEffect } from 'react';
import { API_ROUTES } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import ModalQuantite from '../ModalQuantite/ModalQuantite';
import { UnitforCardArticle } from '../../utils/unitConverter';


import './CardArticle.scss';

function CardArticle({ articles, currentPage, setCurrentPage, articlesPerPage }) {
  const [categories, setCategories] = useState([]);
  const [distributeurs, setDistributeurs] = useState([]);
  const [showVerso, setShowVerso] = useState({});
  const [modalArticle, setModalArticle] = useState(null);

  const toggleModal = (article) => {
    if (modalArticle && modalArticle._id === article._id) {
      setModalArticle(null);
    } else {
      setModalArticle(article);
    }
  };

  useEffect(() => {
    const fetchCategoriesAndDistributors = async () => {
      const categoriesResp = await fetch(API_ROUTES.CATEGORIE);
      const categoriesData = await categoriesResp.json();
      setCategories(categoriesData);
  
      const distributorsResp = await fetch(API_ROUTES.FOURNISSEUR);
      const distributorsData = await distributorsResp.json();
      setDistributeurs(distributorsData);
    };
  
    fetch(API_ROUTES.ARTICLE)
      .then(response => response.json())
      .then(data => 
        fetchCategoriesAndDistributors()
      )
      .catch(error => console.error('Erreur lors du chargement des articles', error));
  }, []);

  const toggleVerso = (id) => {
    setShowVerso(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const findFournisseurById = (list, id) => {
    const fournisseur = list.find(four => four._id === id);
    return fournisseur ? fournisseur.fournisseur : 'N/A';
  };
  
  const findCategorieById = (list, id) => {
    const categorie = list.find(cat => cat._id === id);
    return categorie ? categorie.categorie : 'N/A';
  };
  
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="article-container">
      {modalArticle && <ModalQuantite article={modalArticle} onClose={() => setModalArticle(null)} />}
      <div className='all-article'>
        {currentArticles.map((article, index) => (
          <div key={article._id} className={`article fadeInAnimation`}>
            <div className={`article-content-recto ${showVerso[article._id] ? '' : 'anim'}`}>
              <div className='photo-vrac-local'>
                <div className='article-photo'>
                  <img src={article.imageUrl} alt='Article' />
                </div>
                <div className='article-vrac-local'>
                  <div className='article-vrac'>
                    {article.vrac ? <img src="/assets/vrac-icon.webp" alt="Vrac" className='vrac-logo'/> : null}    
                  </div>
                  <div className='article-local'>
                    {article.local ? <img src="/assets/local-icon.webp" alt="Local" className='local-logo'/> : null}     
                  </div>
                </div>
              </div> 
              <div className='article-details'>
                <div className='article-nom'>{article.nom}</div>
                <div className='article-prix'>{`${article.prix} € / ${UnitforCardArticle(article.mesure, article.unite)}`}</div>
                <div className='infoSupp' onClick={() => toggleVerso(article._id)}>+ d'infos</div>
                <div className='shopping__div' onClick={() => toggleModal(article)}>
                  <FontAwesomeIcon icon={faBasketShopping} className='icon-shopping'/>
                </div>
              </div>
            </div>
            <div className={`verso-article ${showVerso[article._id] ? 'anim' : ''}`}>
              <div className='article-categorie-distributeur'>
                <FontAwesomeIcon icon={faArrowLeft} className='backarrow-icon' onClick={() => toggleVerso(article._id)}/>
                <div className='article-categorie'>
                  {findCategorieById(categories, article.categorie)}
                </div>
                <div className='article-distributeur'>
                  {findFournisseurById(distributeurs, article.distributeur)}
                </div>
              </div>
              <div className='article-description'>
                {article.description}
              </div>
              <div className='article-composition'>
                <span>Composition</span>{article.composition}
              </div>
            </div>
            </div>
        ))}
      </div>
      {/* Pagination */}
      {articles.length > articlesPerPage && (
        <div className="pagination">
          {[...Array(Math.ceil(articles.length / articlesPerPage))].map((_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={currentPage === i + 1 ? "active" : ""}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default CardArticle;
