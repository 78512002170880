import React from 'react';
import Presentation from '../../components/Presentation/Presentation';
import MyCarousel from '../../components/Carousel/Carousel';
import VracTuto from '../../components/VracTuto/VracTuto';

import './Home.scss';

const picturesCarousel = [
  '/assets/Home/Carousel/photoMagasin1.webp',
  '/assets/Home/Carousel/photoMagasin2.webp',
  '/assets/Home/Carousel/photoMagasin3.webp',
  '/assets/Home/Carousel/photoMagasin4.webp',
  '/assets/Home/Carousel/photoMagasin5.webp',
  '/assets/Home/Carousel/photoMagasin6.webp',
  '/assets/Home/Carousel/photoMagasin7.webp',
];

function Home() {

  return (
    <div className='home-container'>
        <Presentation />
        <h1 className="titre-home-cursive">BAsique...</h1>
        <p className='paragraphe-basique'>
            C’est une épicerie <span className="cursive">bio</span> en <span className="cursive">cœur de ville </span> 
            offrant avant tout des produits en <span className="cursive">vrac</span>, avec un objectif : vous proposer un large choix de 
            produits pour y faire <span className="cursive">la plus grande partie de vos courses courantes</span>, tout en adoptant des 
            pratiques de consommation <span className="cursive">zéro déchet</span>.
        </p>
        <MyCarousel pictures={picturesCarousel} />
        <h1 className='titre-home'> Quels produits?</h1>
        <p className='paragraphe-basique'>
            <span className="cursive">Epicerie salée, sucrée, produits frais</span>, mais aussi <span className="cursive">produits d'entretien et d'hygiène</span>,
            <span className="cursive"> BAsique</span> entend <span className="cursive">simplifier</span> vos courses tout en vous permettant de 
            <span className="cursive"> consommer de façon responsable !</span>
        </p>
        <h1 className='titre-home'>Et tout ça, en vrac!</h1>
        <p className='paragraphe-basique'>
            Pour <span className="cursive">se servir en vrac</span>, l'idéal est de venir avec <span className="cursive">ses propres contenants</span> (bocaux, sachets en tissus...),
            mais pas de panique, il y a <span className="cursive">tout ce qu'il faut</span> en boutique en cas de besoin !
        </p>
        <VracTuto />
    </div>
  );
}

export default Home;
