import React from 'react';
import './VracTuto.scss';

function VracTuto () {
    return (
        <div className='vracTuto-container'>
            <div className="case-tuto">
                <p>On <span className='tuto-cursive'>pèse</span> <br />le contenant vide</p>
                <img src='/assets/Home/VracTuto/peserV2.webp' alt='bocal à peser' />
            </div>
            <div className="case-tuto">
                <p>On le <span className='tuto-cursive'>remplit</span> <br />de la quantité <span className='tuto-cursive'>désirée</span></p>
                <img src='/assets/Home/VracTuto/remplirV2.webp' alt='pelle pour remplir son contenant' />
            </div>
            <div className="case-tuto">
                <p>On paie<span className='tuto-cursive'> uniquement</span> <br />le produit</p>
                <img src='/assets/Home/VracTuto/payerV2.webp' alt='prêt pour le passage en caisse' />
            </div>
            <div className="case-tuto">
                <p>On le <span className='tuto-cursive'>conserve</span> selon <br />les <span className='tuto-cursive'>recommandations</span></p>
                <img src='/assets/Home/VracTuto/conserverV2.webp' alt='contenant conserver selon les recommandations' />
            </div>
        </div>
    );
}

export default VracTuto;
