import React, { useState, useEffect } from 'react';
import CardArticle from '../CardArticle/CardArticle';
import { API_ROUTES } from '../../utils/constants';

import './CardProducteur.scss';

function CardProducteur() {
    const [expandedId, setExpandedId] = useState(null);
    const [articles, setArticles] = useState([]);
    const [allArticles, setAllArticles] = useState([]);
    const [producteurs, setProducteurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 3;

    useEffect(() => {
        fetch(API_ROUTES.ARTICLE)
            .then(response => response.json())
            .then(data => {
                setArticles(data);
                setAllArticles(data);
            })
            .catch(error => console.error('Error fetching articles:', error));

        fetch(API_ROUTES.PRODUCTEUR_CARD)
            .then(response => response.json())
            .then(data => {
                setProducteurs(data);
            })
            .catch(error => console.error('Error fetching producteurs:', error));
    }, []);

    const toggleDetails = (producteurId) => {
        if (expandedId === producteurId) {
            setExpandedId(null);
            setArticles(allArticles);
            setCurrentPage(1);
        } else {
            setExpandedId(producteurId);
            const selectedProducteur = producteurs.find(p => p._id === producteurId);
            if (selectedProducteur) {
                const filteredArticles = allArticles.filter(article => article.distributeur === selectedProducteur.fournisseurId);
                setArticles(filteredArticles);
                setCurrentPage(1);
            }
        }
    };

    // Réorganiser la liste de producteurs pour placer la carte sélectionnée en haut
    const reorderedProducteurs = [...producteurs];
    const selectedProducteurIndex = reorderedProducteurs.findIndex(p => p._id === expandedId);
    if (selectedProducteurIndex !== -1) {
        const selectedProducteur = reorderedProducteurs[selectedProducteurIndex];
        reorderedProducteurs.splice(selectedProducteurIndex, 1);
        reorderedProducteurs.unshift(selectedProducteur);
    }

    return (
        <div className='producteurs-articles'>
            {reorderedProducteurs.map((producteur) => (
                <div className='producteur-container-articles' key={producteur._id}>
                    <div className={`producteur-container ${expandedId === producteur._id ? 'extended' : ''}`} onClick={() => toggleDetails(producteur._id)}>
                        <div className='producteur-photo-nom'>
                            <div className={`producteur-photo ${expandedId === producteur._id ? 'grayScale' : ''}`}>
                                <img src={producteur.imageUrl} alt='producteur'/>
                            </div>
                            <div className='producteur-nom'>
                                <span>{producteur.nom}</span>
                            </div>
                        </div>
                        <div className={`producteur-adresse-site ${expandedId === producteur._id ? 'extendedDetails' : ''}`}>
                            <div className='producteur-adresse'>
                                {producteur.adresse}
                            </div>
                            <div className='producteur-site'>
                                {producteur.siteWeb}
                            </div>
                        </div>
                    </div>
                    <div className={`producteur-articles ${expandedId === producteur._id ? 'extendedArticles' : ''}`}>
                        <CardArticle
                            articles={articles}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            articlesPerPage={articlesPerPage}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CardProducteur;
