import React, { useState, useEffect } from 'react';
import './Presentation.scss';
import { API_ROUTES } from '../../utils/constants';


function Presentation () {
    const [horaire, setHoraire] = useState('');
    
    useEffect(() => {
        fetch(API_ROUTES.HORAIRE)
            .then(response => response.json())
            .then(data => setHoraire(data.horaire))
            .catch(error => console.error('Erreur lors du chargement du contenu horaire', error));
    }, []);

    const applyFormatting = (text) => {
        text = text.replace(/\/\*(.*?)\*\//g, '<strong>$1</strong>');
        text = text.replace(/\/\/(.*?)\/\//g, '<em>$1</em>');
        return text;
    };

    return (
        <div className='presentation-container'>
            <img src='/assets/Home/logoBAsiqueLargeTranspRoanne.webp' alt='Logo BAsique, épicerie vrac | 0 déchet | Roanne' className='presentation-logo' />
            <div className='bandeau-container'>
                <img src='/assets/Home/photoEva.webp' alt='Eva, la gérante de BAsique' className='bandeau-image'/>
                <div className='bandeau-adresse-horaire'>
                    <img src='/assets/Home/phrase_bandeau.webp' alt='juste en face du musée!' />
                    <div className='bandeau-adresse-horaire-texte'>
                        <p className='adresse'>
                            17 rue Anatole France | Roanne
                        </p>
                        <p className='horaire' dangerouslySetInnerHTML={{ __html: applyFormatting(horaire) }}></p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Presentation;
