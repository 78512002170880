import React from 'react';
import './Confidentiality.scss';

function Confidentiality() {
  return (
    <div className="confidentiality">
                <h1 className='confidentiality__h1'>Politique de Confidentialité :</h1>
                <p className='confidentiality__p'>Nous attachons une grande importance à la protection de votre vie privée. 
                Nous tenons à vous informer que notre site web ne collecte aucune donnée personnelle des visiteurs, 
                telles que les noms, adresses e-mail, numéros de téléphone, etc.</p>
                <p className='confidentiality__p'>Stockage local :
                Nous utilisons le stockage local (localStorage) pour enregistrer certaines informations, telles que la date et l'heure de 
                fermeture de la modale d'évènement qui s'ouvre lors de votre visite sur le site. Ces données sont utilisées uniquement dans le 
                but de fournir une expérience utilisateur améliorée et ne sont pas partagées avec des tiers.</p>
                <p className='confidentiality__p'>Nous ne faisons pas usage de cookies de suivi ou d'autres technologies 
                similaires pour recueillir des informations sur les utilisateurs de notre site. Vous pouvez naviguer en toute sécurité, 
                en sachant que votre vie privée est respectée.</p>              
    </div>
  );
};

export default Confidentiality;