import React from 'react';
import './Accessibility.scss';

function Accesibility() {
  return (
    <div className="accessibility">
        <div className='accessibility__div'>
            <div className='accesibility-content__div'>
                <h1 className='accessibility__h1'>Politique d'accessibilité :</h1>
                <p className='accessibility__p'>Nous nous engageons à rendre notre site web accessible à tous.
                 Nous sommes déterminés à créer une expérience utilisateur optimale pour chacun de nos visiteurs. 
                 Votre expérience sur notre site est précieuse, c'est pourquoi nous vous encourageons à nous faire 
                 part de vos remarques et suggestions pour améliorer l'accessibilité.</p>
            </div>
            <div className='accessibility-content__div'>
                <h1 className='accessibility__h1'>Moyens de Contact :</h1>
                <p className='accessibility__p'>Vous pouvez contacter le magasin par téléphone au 06.50.85.58.10 ou par e-mail à contact@epicerie-basique.fr. 
                Ces informations sont également disponibles sur notre site web, dans la barre de navigation située dans l'en-tête du site.</p>
            </div>
            <div className='accessibility-content__div'>
                <h1 className='accessibility__h1'>Accessibilité du site :</h1>
                <h2 className='accessibility__h2'>Ajustement de la taille du text :</h2>
                <p className='accessibility__p'>Vous pouvez ajuster la taille du texte en utilisant les paramètres de votre navigateur. 
                Habituellement, cela se fait en appuyant sur les touches Ctrl et + pour agrandir le texte et Ctrl et - pour le réduire.</p>
                <h2 className='accessibility__h2'>Accessibilité physique au Magasin :</h2>
                <p className='accessibility__p'>Le magasin est situé au rez-de-chaussée mais comporte une marche à l'entrée. 
                Pour aider les personnes ayant des difficultés à franchir cette marche, 
                un bouton a été installé pour demander de l'assistance juste devant l'entrée du magasin.</p>
            </div>
        </div>
    </div>
  );
};

export default Accesibility;