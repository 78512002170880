import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { API_ROUTES } from '../../utils/constants';

import './Login.scss';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setIsAdminLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    try {
      const response = await fetch(API_ROUTES.LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        setIsAdminLoggedIn(true);
        navigate('/admin'); // Redirige vers /admin après une connexion réussie
      } else {
        setErrorMessage(data.message || 'Erreur lors de la connexion. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setErrorMessage('Problème de connexion au serveur. Veuillez réessayer plus tard.');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className='login-form'>
        <h1 className='login__h1'>Connexion</h1>
        <div className='login__input'>
          <div className='login-input__div'>
            <label htmlFor="email">Email :</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='login-input__div'>
            <label htmlFor="password">Password :</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button type="submit" className='login__button'>Se connecter</button>
      </form>
    </div>
  );
}

export default Login;
