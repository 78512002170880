export function convertUnit(mesure, unite) {
    if ((unite === 'g' && mesure >= 1000) || (unite === 'ml' && mesure >= 1000)) {
      const convertedMesure = mesure / 1000;
      const newUnite = unite === 'g' ? 'Kg' : 'L';
      return `${convertedMesure.toFixed(2)} ${newUnite}`;
    }
    return `${mesure} ${unite}`;
  }
  
export function UnitforCardArticle(mesure, unite) {
    if (mesure === 1000) {
        const newUnite = unite === 'g' ? 'Kg' : 'L';
        return newUnite;
    }
    if ((unite === 'g' && mesure > 1000) || (unite === 'ml' && mesure > 1000)) {
      const convertedMesure = mesure / 1000;
      const newUnite = unite === 'g' ? 'Kg' : 'L';
      return `${convertedMesure.toFixed(2)} ${newUnite}`;
  }

  return `${mesure} ${unite}`
}