const API_URL = process.env.REACT_APP_API_URL;

export const API_ROUTES = {
    SIGN_UP: `${API_URL}/api/auth/signup`,
    LOGIN: `${API_URL}/api/auth/login`,
    MODALE: `${API_URL}/api/modale`,
    HORAIRE: `${API_URL}/api/horaire`,
    ARTICLE: `${API_URL}/api/articles`,
    CATEGORIE: `${API_URL}/api/categorie`,
    FOURNISSEUR: `${API_URL}/api/fournisseur`,
    PRODUCTEUR_CARD: `${API_URL}/api/producteurcard`,
}

export const APP_ROUTES = {
    LOGIN: '/login',
    ADMIN: '/admin',
    EPICERIE: '/epicerie',
    PRODUCTEURS: '/producteurs',
    PRODUITS: '/produits',
    ACCESSIBILITY: '/accessibility',
    CONFIDENTIALITY: '/confidentiality',
    SITEMAP: '/sitemap',
    WORKING: '/enconstruction'
}