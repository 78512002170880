import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { convertUnit } from '../../utils/unitConverter';
import './ModaleListeCourse.scss';

function ModaleListeCourse({ onClose }) {
  const [listeCourse, setListeCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const listeChargee = JSON.parse(localStorage.getItem('listeCourse')) || [];
    setListeCourse(listeChargee);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => onClose(), 300);
  };

  const handleRemoveItem = (index) => {
    const newListe = [...listeCourse];
    newListe.splice(index, 1);
    setListeCourse(newListe);
    localStorage.setItem('listeCourse', JSON.stringify(newListe));
  };

  return (
    <div className={`modale-liste-course ${isOpen ? '' : 'closing'}`}>
      <div className="overlay" onClick={handleClose}></div>
      <div className="modaleListeCourse-content">
        <h1 className='modaleListeCourse__h1'>Liste de courses</h1>
        {listeCourse.length > 0 ? (
          <ul>
            {listeCourse.map((item, index) => (
              <li key={index}>
                {item.nom} - {convertUnit(item.mesure, item.unite)}
                <button onClick={() => handleRemoveItem(index)} className="remove-item-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>Bientôt disponible</p>
        )}
        <button onClick={handleClose} className="close-button" aria-label="Fermer">
          <FontAwesomeIcon icon={faTimes}/>
        </button>
      </div>
    </div>
  );
}

export default ModaleListeCourse;
