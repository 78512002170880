import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './ModaleContact.scss';


function ModaleContact({ onClose }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => onClose(), 300);
  
  };

  return (
    <div className="modal-backdrop">
      <div className={`overlay ${isOpen ? '' : 'closing'}`} onClick={handleClose}></div>
      <div className={`modal-content ${isOpen ? '' : 'closing'}`}>
        <h1 className='contact__h1'>Contact</h1>
        <div className='contact__div'>
          <p className='contact__p'>Téléphone:</p>
          <a href="tel:06.50.85.58.10" className='contact__p'>06.50.85.58.10</a>
        </div>
        <div className='contact__div'>
          <p className='contact__p'>E-mail:</p>
          <a href="mailto:contact@epicerie-basique.fr" className='contact__p'>contact@epicerie-basique.fr</a>
        </div>
        <div className='contact__div'>
          <p className='contact__p'>Adresse:</p>
          <a href="https://maps.app.goo.gl/1qD9hqRDZv3jgSMB9" target="_blank" rel="noopener noreferrer" className='contact__p'>
            17 rue Anatole France <br />42300 Roanne</a>
        </div>
        <div className='contact-reseau__div'>
          <a href="https://www.facebook.com/epicerie.basique/" target="_blank" rel="noopener noreferrer" aria-label='lien vers Facebook'>
            <FontAwesomeIcon icon={faFacebook} size="2x" className='icon-modal' />
          </a>
          <a href="https://www.instagram.com/epiceriebasique/" target="_blank" rel="noopener noreferrer" aria-label='lien vers Instagram'>
            <FontAwesomeIcon icon={faInstagram} size="2x" className='icon-modal'/>
          </a>
        </div>
        <button onClick={handleClose} className="close-button" aria-label="Fermer">
          <FontAwesomeIcon icon={faTimes} className='close-icon'/>
        </button>      
      </div>
    </div>
  );
}


export default ModaleContact;
